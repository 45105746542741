<template>
  <div
    class="order-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <van-tabs
      v-model:active="menuIndex"
      sticky
      color="var(--van-card-price-color)"
      @change="onChange"
    >
      <template v-for="(item, index) in menus" :key="index">
        <van-tab :title="item" :badge="nums[index] > 0 ? nums[index] : ''">
          <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            :pulling-text="SYSLANG.下拉即可刷新"
            :loosing-text="SYSLANG.释放即可刷新"
            :loading-text="SYSLANG.loading"
          >
            <van-list
              v-model:loading="loading"
              :finished="finished"
              :finished-text="SYSLANG.finished"
              :loading-text="SYSLANG.loading"
              @load="getOrderList"
            >
              <ul class="order-list">
                <template v-for="(item, index) in list" :key="index">
                  <li class="order-item">
                    <div class="order-num" style="margin-bottom:10px;">
                      訂單編號:{{ item.orderMain.orderCode }}
                      <span :class="'status status-' + item.CurStatus">{{
                        item.orderMain.orderStatusDesc
                      }}</span>
                    </div>
                    <router-link :to="'./order/detail?orderid=' + item.orderMain.orderCode">
                      <div class="order-goods-list">
                        <template
                          v-for="(gitem, gindex) in item.detailList"
                          :key="gindex"
                        >
                          <div class="goods">
                            <van-image
                              width="100"
                              height="100"
                              radius="8"
                              :src="gitem.productImage"
                              fit="cover"
                            />
                            <div class="info">
                              <div class="pdt-name">
                                <span class="name">{{
                                  gitem.skuName
                                }}</span>
                              </div>
                              <div class="pdt-props">
                                {{ gitem.properties }}
                              </div>
                              <div class="pdt-props">
                                <span class="price">{{
                                 gitem.retailPrice/100
                                }}</span>
                                <span class="buy-count"
                                  >×{{ gitem.quantity }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </router-link>
                    <div v-if="item.LogisticInfo" class="wuliu">
                      <span>您的快递已签收</span>
                    </div>
                    <div class="total-money">
                      {{ PAGELANG.totalmoney }}
                      <span class="price">{{ item.orderMain.upTotalProductMoney/100+item.orderMain.freight/100}}.00</span>
                    </div>
                    <div class="control">
                      <van-button
                        v-if="item.orderStatus > 2 && item.ShippingNo"
                        plain
                        type="primary"
                        size="small"
                        >{{ PAGELANG.viewwuliu }}</van-button
                      >
                      <van-button
                        v-if="item.orderMain.canCancel == 1"
                        size="small"
                        plain
                        type="danger"
                        @click="cancelOrder(item.orderMain.orderCode)"
                        >{{ PAGELANG.取消订单 }}</van-button
                      >
                      <van-button
                        v-if="item.orderMain.orderStatus == 10 || item.orderMain.orderStatus == 100"
                        size="small"
                        plain
                        type="danger"
                        @click="deleteOrder(item.orderMain.orderCode)"
                        >{{ PAGELANG.删除订单 }}</van-button
                      >
                      <van-button
                        :to="'./order/detail?orderid=' + item.orderMain.orderCode"
                        size="small"
                        >{{ PAGELANG.vieworder }}</van-button
                      >
					  <van-button
					   
					    size="small"
					    plain
					    type="danger"
					    @click="addOrder(item.orderMain.orderCode)"
					    >{{"重新加入购物车"}}</van-button
					  >
                    </div>
                  </li>
                </template>
              </ul>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </template>
    </van-tabs>
  </div>

</template>

<script>
import {
  Tab,
  Tabs,
  showToast,
  showLoadingToast,
  closeToast,
  showConfirmDialog,
} from "vant";
import { getCookie } from "../util";

export default {
  data() {
    return {
      menus: [],
      menuIndex: 0,
      nums: [],
      list: [],
      curpage: 0,
      pagecount: 0,
      pagesize: 0,
      total: 0,
      refreshing: false,
      finished: false,
      loading: false,
      agentInfo: {},
      init: true,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
	console.log('12312312321312')
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.order;
    this.CARTLANG = this.LANG.pages.cart;
    this.UPLOADBASE = this.uploadbase;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    this.rootApp.use(Tab).use(Tabs);
    for (let i = 0; i < this.PAGELANG.menus.length; i++) {
      this.menus.push(this.PAGELANG.menus[i]);
      this.nums.push(0);
    }

    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      this.init = true;
      console.log("getMemberInfo", this.init);
	  
	  this.axios.get(this.actions.orderStatusCount).then((res) => {
	  	if(res.data.code==200){
			let arr=[]
			arr.push(res.data.data.take.all)//全部订单
			arr.push(res.data.data.take.waitPay)//待付款
			arr.push(res.data.data.take.waitShip)//待发货
			arr.push(res.data.data.take.hasShip)//待收货
			// arr.push(res.data.data.waitPay)//已完成
			// arr.push(res.data.data.waitPay)//已取消
			arr.push(res.data.data.take.refund)//退款/售后
	  		this.nums = arr
			closeToast();
			this.init = false
			this.getOrderList();
	  	}
	 
	  });
	  
    
    },
    onChange() {
      this.curpage = 0;
      this.list = [];
      this.pagecount = 0;
      console.log("onRefresh", this.init);
      this.getOrderList();
    },

    getOrderList() {
		
	  console.log('this.init',this.init)
      if (this.init) {
        return false;
      }
      if (this.refreshing) {
        this.refreshing = false;
      }
      this.finished = true;
      this.loading = true;
      this.curpage++;
      this.init = true;
	  let orderStatus=""
	  if(this.menuIndex==1){
		  orderStatus=1
	  }
	  if(this.menuIndex==2){
	  		  orderStatus=3
	  }
	  if(this.menuIndex==3){
	  		  orderStatus=4
	  }
	  if(this.menuIndex==4){
	  		  orderStatus=6
	  }
	  
      this.axios
        .get(this.actions.orderList,{params:{
          page: this.curpage,
          orderStatus:orderStatus,
		  type:1,
		  pageSize:20,
		  partShipStatus:"",
		  transferStatus:"",
		  userType:"buyer",
		  refundType:1,
        }})
        .then((res) => {
          closeToast();
          this.init = false;
          let { page, pageTotal, pagesize, total} = res.data.data;
		  let list=res.data.data.list
          if (res.data.code == 200) {
            this.curpage = page * 1;
            this.pagecount = pageTotal * 1;
            this.pagesize = pagesize * 1;
            this.total = total * 1;
            this.list = this.list.concat(list);
            console.log("order list ",this.list);
          } else {
            showToast({
              message:
                res.data.code == "NOTLOGIN" ? this.SYSLANG[res.data.message] : this.PAGELANG[res.data.message],
              forbidClick: true,
              onClose: () => {
                if (res.data.code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
          this.loading = false;
          this.finished = false;
          if (this.curpage >= this.pagecount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          console.log("views.Order.getOrderList.error", error);
          closeToast();
         // this.curpage--;
          //this.getOrderList();
        });
    },

    onRefresh() {
      this.loading = false;
      this.finished = false;
      this.list = [];
      this.curpage = 0;
      this.pagecount = 0;

      if (!this.init) {
        this.getOrderList();
      }
    },

    changePage(val) {
      this.curpage = val - 1;
      this.goods = [];
      this.getOrderList();
    },

    // 取消订单
    cancelOrder(orderid) {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.您确定要取消当前订单,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.PAGELANG.正在取消订单,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .post(this.actions.orderCancel,{orderCode:orderid})
            .then(({ data }) => {
              closeToast();
              let { code } = data;
              if (code == 200) {
                this.curpage = 0;
                this.list = [];
                this.pagecount = 0;
                this.getMemberInfo();
              }
            })
            .catch((error) => {
              //请求失败，触发catch中的函数 可省略
              console.log("views.Order.cancelOrder.order_cancel.error", error);
              closeToast();
            });
        })
        .catch(() => {});
    },
     addOrder(orderid) {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message:"您确定要重新加入购物车嘛",
        confirmButtonText:"加入",
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message:"正在加入",
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .get(this.actions.orderAdd,{params:{orderCode: orderid,}})
            .then(({ data }) => {
              closeToast();
              let { code } = data;
              if (code == 200) {
                  showLoadingToast({
                    message:"重新加入购物车成功",
                    duration:1000,
                    forbidClick: true,
                  });
              }
            })
            .catch((error) => {
              //请求失败，触发catch中的函数 可省略
              console.log("views.Order.deleteOrder.order_delete.error", error);
              closeToast();
            });
        })
        .catch(() => {});
    },
    // 删除订单
    deleteOrder(orderid) {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.您确定要删除当前订单,
        confirmButtonText: this.SYSLANG.delete,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.PAGELANG.正在删除订单,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .post(this.actions.orderDelete,{orderCode:orderid})
            .then(({ data }) => {
              closeToast();
              let { code } = data;
              if (code == 200) {
                this.curpage = 0;
                this.list = [];
                this.pagecount = 0;
                this.getMemberInfo();
              }
            })
            .catch((error) => {
              //请求失败，触发catch中的函数 可省略
              console.log("views.Order.deleteOrder.order_delete.error", error);
              closeToast();
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style src="../assets/css/order.css" scoped></style>
<style scoped>
@media (max-width: 678px) {
  .pc-my-page {
    display: none;
  }
  .order-page {
    width: 100%;
    min-height: 100vh;
    background-color: #f7f7f9;
    --van-tabs-line-height: 60px;
    --van-tab-font-size: 16px;
  }

  .order-page .price::before {
    content: var(--aog-currency);
    font-size: 70%;
  }

  .order-page .order-list {
    padding: 3vw;
  }

  .order-page .order-list .order-item {
    padding: 3vw;
    background-color: #fff;
    border-radius: 6px;
    font-size: 14px;
    margin-bottom: 3vw;
  }

  .order-page .order-list .order-item .order-num {
    display: flex;
    height: 7vw;
    justify-content: space-between;
    font-size: 16px;
  }

  .order-page .order-list .order-item .order-num .status {
    color: #f14b6d;
  }
  .order-page .order-list .order-item .order-num .status.status-1::before {
    content: var(--aog-order-status-1);
  }
  .order-page .order-list .order-item .order-num .status.status-2::before {
    content: var(--aog-order-status-2);
  }
  .order-page .order-list .order-item .order-num .status.status-3::before {
    content: var(--aog-order-status-3);
  }
  .order-page .order-list .order-item .order-num .status.status-4::before {
    content: var(--aog-order-status-4);
    color: #4e4e4e;
  }
  .order-page .order-list .order-item .order-num .status.status-5::before {
    content: var(--aog-order-status-5);
  }

  .order-page .order-list .order-item .wuliu {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #f9f9f9;
    padding: 0 3vw;
    border-radius: 6px;
    color: #555;
  }
  .order-page .order-list .order-item .wuliu span {
    width: 0;
    flex-grow: 1;
  }

  .order-page .order-list .order-item .wuliu::after {
    content: "\e68b";
    font-family: "iconfont";
  }

  .order-page .order-list .order-item .total-money {
    line-height: 50px;
    color: #000;
    text-align: right;
    font-size: 16px;
  }
  .order-page .order-list .order-item .total-money .price {
    font-size: 22px;
    font-weight: bold;
    margin-left: 4px;
  }

  .order-page .order-list .order-item .control {
    display: flex;
    justify-content: flex-end;
    --van-button-small-height: 36px;
    --van-button-small-padding: 0 12px;
    --van-button-small-font-size: 14px;
    --van-button-primary-border-color: #d5d5d5;
    --van-button-primary-background: #1d1d1d;
    --van-button-default-background: #fe295f;
    --van-button-default-border-color: #fe295f;
    --van-button-danger-background: #fe295f;
    --van-button-default-border-color: #fe295f;
    --van-button-default-color: #fff;
  }

  .order-page .order-list .order-item .control .van-button {
    margin-left: 10px;
  }
}

@media (min-width: 679px) {
  .order-page {
    display: none;
  }
  .demo-tabs {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #fff;
  }
  .pc-my-page .main >>> .el-tabs {
    --el-color-primary: var(--van-card-price-color);
    --el-tabs-header-height: 50px;
    --el-font-size-base: 16px;
  }
  .pc-my-page .main >>> .el-tabs .el-tabs__item {
    padding: 0 30px;
  }

  .pc-my-page .main .order-list {
    padding: 30px;
  }

  .pc-my-page .main .order-list .orderitem {
    margin-bottom: 20px;
    line-height: 24px;
    font-size: 14px;
  }
  .pc-my-page .main .order-list .orderitem >>> .el-card__header {
    padding-top: 0;
    padding-bottom: 0;
    background: #e3e3e3;
  }
  .pc-my-page .main .order-list .orderitem >>> .el-card__body {
    padding-top: 0;
    padding-bottom: 10px;
  }
  .pc-my-page .main .order-list .orderheader {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
  }
  .pc-my-page .main .order-list .orderheader h5 {
    margin-bottom: 0;
    margin-top: 0;
    line-height: 24px;
    margin-right: 20px;
  }
  .pc-my-page .main .order-list .pdtcount {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 20px;
  }
  .pc-my-page .main .order-list .pdtinfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 0;
    flex-grow: 1;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .pc-my-page .main .order-list .pdtinfo .name-and-attr {
    display: flex;
    width: 0;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .pc-my-page .main .order-list .pdtinfo .pdtimage {
    width: 100px;
    height: 100px;
  }
  .pc-my-page .main .order-list .orderdetail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #e3e3e3;
  }
  .pc-my-page .main .order-list .orderitem .orderdetail:last-child {
    border-bottom: 0;
  }

  >>> .el-tabs__nav-next,
  >>> .el-tabs__nav-prev {
    line-height: var(--el-tabs-header-height);
  }
}
</style>
